.footer {
  padding: 0 0 100px;
  font-size: 14px;
  line-height: var(--title-line-height);

  @include respond-down(S) {
    padding: 0 0 80px;
    font-size: 12px;
    line-height: 15px;
  }

  @include respond-down(XS) {
    padding: 0 0 60px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 280px;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      height: 365px;
    }

    @include respond(S) {
      height: 289px;
    }

    @media screen and (max-width: 610px) {
      height: 359px;
    }

    @include respond(XS) {
      height: 425px;
    }
  }

  &__top-container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 980px) {
      flex-direction: column;
    }
  }

  &__wrapper-logo {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    margin-left: -15px;
    height: 94px;

    @include respond(S) {
      height: 70px;
    }

    @include respond(XS) {
      height: 50px;
    }
  }

  &__logo {
    display: flex;

    svg {
      width: 175px;
      height: 37px;
    }

    @include respond(S) {
      svg {
        width: 110px;
        height: 23px;
      }
    }

    @include respond(XS) {
      svg {
        width: 55px;
        height: 11.5px;
      }
    }
  }

  &__logo-title {
    line-height: 1;
    padding-left: 1px;
    font-size: 14.5px;
    letter-spacing: 0.03em;

    @include respond(S) {
      font-size: 9px;
    }

    @include respond(XS) {
      font-size: 4.5px;
    }
  }

  &__menu-list {
    padding-top: 13px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -15px;

    @media screen and (max-width: 980px) {
      padding-top: 0;
      margin-left: -15px;
    }

    @include respond-down(S) {
      margin: 0 -9px;
    }
  }

  &__menu-item {
    @include respond-down(S) {
      flex: 0 0 50%;
    }
  }

  &__menu-link {
    display: flex;
    padding: 15px;
    position: relative;

    @include respond-up(L) {
      &:after {
        display: block;
        position: absolute;
        left: 50%;
        top: 4px;
        transform: translate(-50%, -50%);
        height: 0;
        width: 0;
        border-radius: 50%;
        content: '';
        background: var(--col_yellow);
        box-shadow: -2px -2px 4px 0px #FFFFFF, 2px 2px 4px 0px #E5E5E5;
        transition: var(--default-transition);
      }

      &:hover:after {
        height: 8px;
        width: 8px;
        transition: var(--default-transition);
      }
    }

    @include respond-down(S) {
      padding: 10px;
    }
  }

  &__bottom-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-up(M) {
      align-items: center;
    }

    @include respond(XS) {
      flex-direction: column;
    }
  }

  &__wrapper-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include respond(XS) {
    }
  }

  &__socials {
    display: flex;
    align-items: center;
  }

  &__social-link {
    display: flex;
    padding: 15px;
    margin-left: -15px;
    align-items: center;

    @include respond-up(L) {
      &:hover {
        svg {
          path {
            fill: black;
          }
        }
      }
    }

    svg {
      display: flex;
      margin-right: 6px;

      path {
        transition: fill .3s ease-in-out;
      }
    }

    @include respond-down(S) {
      padding: 9px;
      margin-left: -9px;
    }
  }

  &__phone {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    padding: 9px;
    margin-left: -9px;
    margin-right: 15px;

    @include respond-down(S) {
      font-size: 20px;
      line-height: 24px;
      padding: 7px;
      margin-left: -7px;
    }
  }

  &__address {
    padding: 14px;
    margin-left: -14px;

    @include respond-down(S) {
      padding: 6px 8px 8px;
      margin-left: -8px;
    }
  }

  &__wrapper-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__policy {
    padding: 14px;
    margin-right: -14px;

    @include respond-down(S) {
      padding: 9px;
      margin-right: -9px;
    }
  }

  &__copy {
    padding: 14px;
    margin-right: -14px;

    @include respond-down(S) {
      padding: 9px 9px 2px;
      margin-right: -9px;
    }
  }

  &__wrapper-developer {
    display: flex;
    align-items: center;
    padding: 0 14px;
    margin-right: -14px;

    @include respond-down(S) {
      padding: 0 9px;
      margin-right: -9px;
    }
  }

  &__developer-info {
    margin-right: 5px;
  }

  &__developer-icon {
    display: flex;
  }
}
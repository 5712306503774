.stocks {
  display: flex;
  position: relative;
  margin: 0 -12px;
  align-items: flex-start;

  &__column {
    display: block;
    position: relative;
    flex: 0 0 25%;
    width: 25%;
    padding: 0 12px;

    @include respond-down(L) {
      flex: 0 0 33.3333333%;
      width: 33.3333333%;
    }

    @include respond-down(M) {
      flex: 0 0 50%;
      width: 50%;
    }

    @include respond-down(XS) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}
.main-form {
  position: relative;
  z-index: 0;
  background-color: var(--col_main-grey);
  border: 1px solid var(--col_main-border);
  padding: 96px 0;

  @include respond-down(M) {
    padding: 48px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 20px;

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 33px;
    width: 418px;

    @include respond-down(M) {
      font-size: 16px;
      margin-bottom: 10px;
      width: 262px;
    }

    @include respond(XS) {
      margin-bottom: 21px;
    }
  }

  &__wrapper-line {
    display: flex;
    align-items: center;
    padding-left: 335px;

    @include respond-down(L) {
      padding-left: 0;
      align-items: flex-start;
    }

    @include respond-down(M) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__field-phone {
    width: 310px;
    margin-right: 40px;

    @include respond-down(L) {
      width: 333px;
    }

    @include respond-down(M) {
      margin-right: 0;
      margin-bottom: 42px;
    }

    @include respond(XS) {
      width: auto;
      margin-bottom: 32px;
    }
  }

  &__wrapper-column {
    display: flex;
    align-items: center;
    padding-top: 20px;

    @include respond-down(L) {
      flex-direction: column;
      align-items: stretch;
    }

    @include respond-down(M) {
      padding-top: 0;
    }
  }

  &__button {
    width: 310px;
    margin-right: 25px;

    @include respond-down(L) {
      width: 333px;
      margin-right: 0;
      margin-bottom: 24px;
    }

    @include respond(XS) {
      width: auto;
      margin-bottom: 16px;
    }
  }

  &__policy {
    width: 310px;

    @include respond-down(L) {
      width: 333px;
    }

    @include respond(XS) {
      width: auto;
    }
  }
}
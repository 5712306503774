.material-page {
  &__title-row {
    @include respond-up(M){
      padding-left: 42%;
    }
  }

  &__h1 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 24px;

    @include respond-down(S){
      font-size: 22px;
      margin-bottom: 14px;
    }
  }
}
.scroll-block {
  margin: 0 -20px;
  overflow: hidden;

  &__inner-container {
    margin: 0 auto -15px;
    overflow-x: scroll;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    padding: 30px 20px;

    @include respond-down(M) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }

    @include respond(XS) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;
      width: calc(400% - 100px);
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    border: 1px solid #EDEDED;
  }

  &__link {
    display: block;
  }

  &__image {
    display: block;
    //padding-top: 60%;

    //background: {
    //  repeat: no-repeat;
    //  size: cover;
    //  position: center;
    //}

    //img {
    //  display: none;
    //}
  }

  &__label {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    left: 16px;
    bottom: 16px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    padding: 8px 24px;
    background: linear-gradient(98.27deg, #EDEDED, #EDEDED);
    border: 1px solid #B6B6B6;
    border-radius: 16px 0;

    @include respond-up(L) {
      transition: var(--default-transition);
    }
  }

  &__icon {
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
    height: 12px;
    width: 24px;
    margin-right: 10px;
  }

  &__button {
    position: absolute;
    z-index: 2;
    right: 16px;
    bottom: 16px;
  }

  &__info {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 20px;
    opacity: 0;
    transition: var(--default-transition);
    background-color: white;

    @include respond-down(L) {
      padding: 5px 10px;
    }
  }

  &__text {
    @include respond-up(L) {
      font-weight: bold;
    }
    @include respond-down(M) {
      display: none;
    }
  }

  &__text-short {
    font-size: 14px;
    @include respond-up(XL) {
      display: none;
    }
  }

  &_with-info {
    .scroll-block__link {
      @include respond-up(L) {
        &:hover {
          .scroll-block__info {
            opacity: 0.8;
            transition: var(--default-transition);
          }
        }
      }
    }
  }

  &_with-button {
    .scroll-block__link {
      @include respond(L) {
        &:hover {
          .scroll-block__label {
            opacity: 0;
            transition: var(--default-transition);
          }
        }
      }

      @include respond-up(L) {
        &:hover {
          .scroll-block__button {
            opacity: 1;
            transition: var(--default-transition);
          }
        }
      }
    }
  }

  &_label-on-top {
    .scroll-block__label {
      left: auto;
      bottom: auto;
      right: 14px;
      top: 11px;
      font-weight: normal;
    }

    .scroll-block__icon {
      display: block;
    }

  }
}

.reviews {
  display: flex;
  position: relative;
  margin: 0 -12px;
  align-items: flex-start;

  &__column {
    display: block;
    position: relative;
    flex: 0 0 50%;
    width: 50%;
    padding: 0 12px;

    @include respond-down(M) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}
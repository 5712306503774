.checkbox-list-image {

  &__item {
    margin-bottom: 16px;


  }
  &__label {
    background: linear-gradient(101.6deg, #FFFFFF 0%, #F0F0F0 100%);
    border: 1px solid #EDEDED;
    box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #E5E5E5;
    padding: 7px 24px;
    display: block;
    border-radius: 16px;
    transition: var(--default-transition);
    text-align: left;
    padding-left: 68px;

    @include respond-down(M) {
      padding: 11px 24px;
    }

    position: relative;
    z-index: 2;

  }

  &__input {
    display: none;

    &:checked+label{
      background: linear-gradient(109.12deg, #F0F0F0 0%, #E5E5E5 100%);
      box-shadow: inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #D6D6D6;
      border-radius: 16px;

    }
  }



  &__icon {
    top: 50%;
    transform: translateY(-50%);
    left: 38px;
    position: absolute;
    z-index: 3;
    display: block;
    background: {
      position: center;
      size: cover;
    };

    width: 16px;
    height: 16px;
  }
}
.about {
  background-color: var(--col_main-grey);
  border: 1px solid var(--col_main-border);
  box-shadow: -12px -12px 24px #FFFFFF, 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 96px;

  @include respond-down(M) {
    margin-bottom: 69px;

    .half-container {
      padding: 0;
    }
  }

  @include respond(XS) {
    margin-bottom: 40px;
  }

  &__inner {
    display: flex;

    @include respond-down(M) {
      flex-direction: column-reverse;
    }
  }

  &__content {
    @include respond-up(L) {
      flex-basis: 58%;
      padding: 100px 4.6% 112px 0;

      .container {
        padding: 0;
      }
    }

    @include respond-down(M) {
      padding: 48px 0 59px;
    }

    @include respond(XS) {
      padding: 24px 0 27px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 67px;

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 24px;
    }

    @include respond(XS) {
      margin-bottom: 12px;
    }
  }

  &__info {
    font-size: 20px;

    @include respond-down(M) {
      font-size: 16px;
    }

    @include respond(XS) {
      font-size: 14px;
    }
  }

  &__text {
    display: block;

    @include respond-up(L) {
      &:nth-child(odd) {
        padding-right: 13.3%;
      }

      &:nth-child(even) {
        padding-left: 13.3%;
      }
    }

    &:not(:last-child) {
      margin-bottom: 56px;

      @include respond-down(M) {
        margin-bottom: 16px;
      }

      @include respond(XS) {
        margin-bottom: 12px;
      }
    }
  }

  &__image {
    @include respond-up(L) {
      flex-basis: 42%;
    }

    display: block;

    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    img {
      display: none;
    }

    @include respond-down(M) {
      height: 220px;
    }
  }
}
.stock-preview {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 36px;
  background-color: var(--col_main-grey);
  border: 1px solid var(--col_main-border);
  border-radius: 16px 0;
  overflow: hidden;
  transition: var(--default-transition);

  @include respond-up(L) {
    &:after {
      border-radius: 16px 0;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(96.1deg, var(--col_yellow), var(--col_yellow-hover));
      opacity: 0;
      transition: var(--default-transition);
    }

    &:hover {
      box-shadow: -8px -8px 16px var(--col_main-white), 8px 8px 16px var(--col_main-border), inset 4px 4px 20px var(--col_main-yellow);
      transition: var(--default-transition);

      &:after {
        opacity: 1;
        transition: var(--default-transition);
      }
    }
  }

  &__image {
    z-index: 1;
    display: block;
    padding-top: 48%;

    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    img {
      display: none;
    }
  }

  &__wrapper-info {
    z-index: 1;
    display: block;
    padding: 32px 32px 36px;

    @include respond-down(M){
      padding: 24px 32px 32px;
    }
  }

  &__title {
    display: block;
    line-height: var(--title-line-height);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 13px;

    @include respond-down(M){
      font-size: 16px;
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    display: flex;

    @include respond-down(M) {
      font-size: 12px;
    }
  }

  &__text {
    display: block;
    margin-right: 8px;
    font-weight: bold;
  }
}
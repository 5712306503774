/**

Задаёт фиксированные отступы по краям контейнера

Example:

$grid: (
  small: (
    from: 0,
    to: 977,
    spacer: 40
  ),
  large: (
    from: 978,
    spacer: 20
  )
);

*/

$grid: (
        XS: (
                from: 0,
                to: 576,
                spacer: 20
        ),
        S: (
                from: 577,
                to: 768,
                spacer: 20
        ),
        M: (
                from: 769,
                to: 1024,
                spacer: 30
        ),
        L: (
                from: 1025,
                to: 1440,
                spacer: 30
        ),
        XL: (
                from: 1441,
                spacer: 40
        )
);
/**
Этот параметр изменяемый, например на 'vw', если сайт тянется
По умолчанию - 'px'
 */
$spacer-unit: 'px';

@include respond(XL){
  :root{
    --max-row-width:1713;
  }
}

@include respond(L){
  :root{
    --max-row-width: 1310;

  }
}

.container {
  max-width: calc(var(--max-row-width) * 1px);
  margin: 0 auto;
}

@each $name, $data in $grid {
  @if map-has_key($data, spacer) {
    @include respond($name) {
      .container {
        &_spacer {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-up($name) {
      .container {
        &_spacer-#{$name}-up {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-down($name) {
      .container {
        &_spacer-#{$name}-down {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }
  }
}

.half-container {
  max-width: calc((100vw - var(--max-row-width) * 1px) / 2 + var(--max-row-width) * 1px);
  margin-left: auto;
}

@each $name, $data in $grid {
  @if map-has_key($data, spacer) {
    @include respond($name) {
      .half-container {
        &_spacer {
          padding: 0 0 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-up($name) {
      .half-container {
        &_spacer-#{$name}-up {
          padding: 0 0 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-down($name) {
      .half-container {
        &_spacer-#{$name}-down {
          padding: 0 0 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }
  }
}
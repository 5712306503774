.review-preview {
  display: block;
  background: var(--col_main-grey);
  border: 1px solid var(--col_main-border);
  border-radius: 16px;
  padding: 32px 42px;
  margin-bottom: 24px;

  @include respond-down(L){
    padding: 32px 25px;
  }

  @include respond-down(M) {
    font-size: 14px;
    padding: 24px 32px;
    margin-bottom: 16px;
  }

  @include respond(XS) {
    padding: 24px 16px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    margin-bottom: 52px;

    @include respond(M) {
      margin-bottom: 44px;
    }

    @include respond(XS) {
      flex-direction: column;
      margin-bottom: 9px;
    }
  }

  &__name {
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 68px;
      background-color: black;
      transform: translateY(16px);

      @include respond(M) {
        width: 57px;
        transform: translateY(20px);
      }

      @include respond(XS) {
        display: none;
      }
    }

    @include respond(XS) {
      margin-bottom: 9px;
    }
  }

  &__date {
    color: var(--col_grey);
  }

  &__information {
    display: flex;

    @include respond(XS) {
      flex-direction: column;
    }
  }

  &__text {
    margin-right: 8%;

    @include respond(M) {
      margin-right: 32px;
    }

    @include respond(XS) {
      margin-right: 0;
      margin-bottom: 9px;
    }
  }

  &__photo {
    margin-top: 6px;
    width: 148px;
    height: 148px;
    object-fit: cover;
    object-position: center;

    @include respond(XS) {
      margin-top: 0;
      align-self: center;
      min-width: 100%;
      height: auto;
    }
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  &_yellow {
    background: linear-gradient(106.61deg, var(--col_yellow) 50%, #F2CA6E 100%);
    background-size: 200%;
    box-shadow: -12px -12px 24px #FFFFFF, 12px 12px 24px #D6D6D6, inset 4px 4px 20px #F2CA6E;
    padding: 18px 56px;

    @include respond-up(L) {
      transition: var(--default-transition);

      &:hover {
        background-position: 100%;
        box-shadow: -16px -16px 32px #FFFFFF, 16px 16px 32px #D6D6D6, inset 4px 4px 20px #F2CA6E;
        transition: var(--default-transition);
      }

      &:active {
        box-shadow: inset -4px -4px 8px #FFFFFF, inset 4px 4px 8px #D6D6D6;
      }
    }

    @include respond-down(M) {
      padding: 10px 32px;
    }
  }

  &_white {
    background: linear-gradient(92.29deg, #F0F0F0, #E5E5E5, #D6D6D6);
    background-size: 200%;
    box-shadow: -4px -4px 8px #FFFFFF, 4px 4px 8px #E2E2E2;
    padding: 8px 24px;

    @include respond-up(L) {
      transition: var(--default-transition);

      &:hover {
        background-position: 100%;
        box-shadow: -12px -12px 24px #FFFFFF, 12px 12px 24px #E5E5E5;
        transition: var(--default-transition);
      }
    }

    &._press {
      background-position: 0;
      box-shadow: inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #D6D6D6;
    }
  }

  &_press {
    background-position: 0;
    box-shadow: inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #D6D6D6;
  }

  &_big-square {
    padding: 0;
    border-radius: 4px;
    width: 40px;
    height: 40px;

    @include respond(XS) {
      width: 30px;
      height: 30px;

      svg {
        width: 18px;
        height: 11px;
      }
    }
  }

  &_small-square {
    padding: 0;
    border-radius: 4px;
    width: 28px;
    height: 28px;
  }

  &_upper {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    @include respond-down(M) {
      font-size: 14px;
    }
  }

  &_header {
    padding: 12px 38px;
    width: 100%;

    @include respond(L) {
      padding: 12px 18px;
    }

    @include respond-down(L) {
      font-size: 14px;
    }

    @include respond-down(M) {
      font-size: 14px;
      padding: 9px 32px;
    }

    @include respond(XS) {
      font-size: 12px;
      padding: 5.5px 10px;
    }
  }

  &_material {
    padding: 8px 56px;
  }

  &_form {
    font-size: 20px;
    line-height: 1.6;
    padding: 15px 0;

    @include respond(XS) {
      font-size: 14px;
      padding: 10px 0;
    }
  }

  &_kitchen {
    @include respond(L) {
      font-size: 15px;
    }
  }

  &_error {
    padding: 12px 82px;
    font-size: 14px;
    width: 253px;
    height: 46px;

    @include respond(XS) {
      padding: 10px 0;
    }
  }

  &_menu {
    padding: 14px 41px;

    @include respond(S) {
      padding: 14px 31px;
    }

    @include respond(XS) {
      padding: 10px 41px;
    }
  }

  &_tiny {
    padding: 4px 24px;
    font-size: 14px;
    letter-spacing: 0.05em;
    box-shadow: inset 4px 4px 20px var(--col_yellow);
    opacity: 0;

    @include respond-up(L) {
      transition: var(--default-transition);

      &:hover {
        box-shadow: inset 4px 4px 20px var(--col_yellow);
        transition: var(--default-transition);
      }
    }
  }
}
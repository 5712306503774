.product-preview {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  height: fit-content;

  &_sale {
    .product-preview__sale-block {
      display: flex;
    }
  }

  &_double-wight {
    @include respond-up(S) {
      .product-preview__image {
        //padding-top: 31%;
      }
    }
  }

  &__sale-block {
    display: none;
    top: 8px;
    right: -9px;
  }

  &__image {
    display: flex;
    //padding-top: 75%;
    margin-bottom: 24px;

    background: {
      repeat: no-repeat;
      size: contain;
      position: center;
    };

    img {
      width: 100%;
    }

    @include respond-down(L) {
      margin-bottom: 16px;
    }

    @include respond(S) {
      margin-bottom: 20px;
    }

    @include respond(XS) {
      margin-bottom: 12px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 26px 40px;

    @include respond-down(L) {
      padding: 0 16px 30px;
    }

    @include respond(S) {
      padding: 0 27px 20px 27px;
    }

    @include respond(XS) {
      padding: 0 22px 20px 22px;
    }
  }

  &__product-name {
    display: block;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 18px;

    @include respond-down(L) {
      font-size: 18px;
    }

    @include respond-down(S) {
      margin-bottom: 12px;
      font-size: 16px;
    }
  }

  &__wrapper-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__price {
    position: relative;
    z-index: 1;
    font-size: 22px;
    margin-right: 33px;

    @include respond-down(L) {
      font-size: 19px;
      margin-right: 25px;
    }

    @include respond(S) {
      font-size: 18px;
      margin-right: 22px;
    }

    @include respond(XS) {
      font-size: 16px;
      margin-right: 14px;
    }

    &_old {
      padding: 0 6px;
      font-size: 14px;
      color: #000000;

      @include respond-down(S) {
        font-size: 12px;
      }

      &:after {
        content: '';
        z-index: 2;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        background: var(--col_yellow);
        height: 2px;
        left: 0;
        right: 0;
        display: block;
      }
    }
  }

  &__button {
    align-self: center;
    position: absolute;
    bottom: 0;
    z-index: 2;
    transform: translateY(50%);

    @include respond-up(L) {
      opacity: 0;
      pointer-events: none;
      transition: var(--default-transition);
      padding: 18px 49px;
    }

    @include respond(L) {
      font-size: 18px;
      padding: 9px 22px;
    }

    @include respond-down(M) {
      width: 100%;
      transform: translateY(28px)
    }

    @include respond(XS) {
      width: auto;
      transform: translateY(31px);
    }
  }

  @include respond-up(M) {
    &:hover {
      .product-preview__button {
        pointer-events: auto;
        opacity: 1;
        transition: var(--default-transition);
      }
    }
  }
}
.contacts {
  &__map {
    height: 500px;
    box-sizing: border-box;
    border: 1px solid var(--col_main-border);
  }

  &__background {
    position: relative;
    top: -50px;
    display: flex;
    background-color: var(--col_main-grey);
    border-radius: 0 16px;
    border: 1px solid var(--col_main-border);
    overflow: hidden;

    @include respond-down(M) {
      flex-direction: column;
      border-radius: 0;
    }
  }

  &__info {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--col_main-border);
    padding: 56px 5.9%;

    @include respond-down(M) {
      border-right: none;
      border-bottom: 1px solid var(--col_main-border);
      padding: 56px 50px;
    }

    @include respond(XS) {
      padding: 32px 24px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 32px;

    @include respond-down(M) {
      font-size: 22px;
    }

    @include respond(XS) {
      margin-bottom: 24px;
    }
  }

  &__wrapper-field {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;

    &_social {
      flex-direction: row;
    }

    @include respond-down(M) {
      font-size: 16px;
      margin-bottom: 24px;
    }

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__name {
    color: var(--col_contacts-name);
  }

  &__value {
    font-weight: bold;
    padding: 10px;
    margin: -10px;
  }

  &__wrapper-line {
    display: flex;
    justify-content: space-between;

    @include respond-down(L) {
      flex-direction: column;
    }
  }

  &__wrapper-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__social {
    display: block;
    padding: 10px;
    margin: -10px;
  }

  &__form {
    position: relative;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    padding: 80px 5%;

    @include respond-down(M) {
      padding: 48px 4.5%;
    }

    @include respond(S) {
      align-items: center;
    }

    @include respond(XS) {
      padding: 48px 24px;
    }

    .form__block-success {
      .form__success-image {
        margin-bottom: 14px;
      }
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;

    @include respond(S) {
      width: 333px;
    }
  }

  &__form-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 8px;
    display: none;

    @include respond-down(M) {
      display: inline;
    }
  }

  &__form-subtitle {
    font-size: 24px;
    max-width: 418px;
    margin-bottom: 33px;

    @include respond-down(M) {
      font-size: 16px;
      max-width: none;
      margin-bottom: 36px;
    }

    @include respond(XS) {
      display: none;
    }
  }

  &__form-subtitle-hidden {
    margin-bottom: 21px;
    display: none;

    @include respond(XS) {
      display: inline;
    }
  }

  &__form-container {
    display: flex;
    align-items: flex-start;

    @include respond-down(L) {
      flex-direction: column;
      width: 333px;
    }

    @include respond(XS) {
      width: auto;
    }
  }

  &__form-phone {
    margin-right: 40px;

    @include respond-down(L) {
      margin-right: 0;
      margin-bottom: 44px;
    }

    @include respond(XS) {
      margin-bottom: 24px;
    }
  }

  &__form-column {
    padding-top: 20px;
    display: flex;
    flex-direction: column;

    @include respond-down(L) {
      padding-top: 0;
    }
  }

  &__form-button {
    margin-bottom: 26px;

    @include respond-down(M) {
      margin-bottom: 24px;
    }

    @include respond(XS) {
      margin-bottom: 15px;
    }
  }

  &__form-background {
    position: absolute;
    bottom: 40px;
    left: 62%;
    width: 210px;
    height: 282px;
    display: none;

    @include respond(M) {
      display: block;
    }
  }
}
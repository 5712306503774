:root{
  --modal-bg: rgba(0, 0, 0, 0.76);
  --modal-content-background: var(--col_main-grey);
  --modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27);
  --modal-preloader-size: 30px;
}

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }

  @include respond(XS) {
    padding: 72px 24px 24px;
  }
}

.modal__container {
  background-color: var(--modal-content-background);
  border: 1px solid var(--col_main-border);
  box-shadow: var(--modal-content-shadow);
  position: relative;
  padding: 96px 112px;
  width: 528px;

  @include respond(XS) {
    padding: 42px 28px;
    width: 272px;
  }
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  display: block;
  position: absolute;
  text-decoration: none;
  cursor: pointer;
  top: -1px;
  right: -9px;
  transform: translateX(100%);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  background: linear-gradient(92.29deg, #F0F0F0, #E5E5E5, #D6D6D6);
  background-size: 200%;

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: {
      image: url('/static/images/svg/close.svg');
      repeat: no-repeat;
      position: center;
    }

    @include respond-down(XS) {
      background-size: 13px;
    }
  }

  @include respond-up(L) {
    transition: var(--default-transition);

    &:hover {
      background-position: 100%;
      transition: var(--default-transition);
    }
  }

  @include respond-down(S) {
    right: -1px;
    top: -9px;
    transform: translateY(-100%);
  }

  @include respond-down(XS) {
    width: 32px;
    height: 32px;
    top: -7px;
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--modal-bg);
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  --primary-color-opacity: rgba(1, 199, 103, .85);
  --preloader-size: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: var(--preloader-size);
      height: var(--preloader-size);
      left: 50%;
      top: 50%;
      margin-left: calc(var(--preloader-size)/-2);
      margin-top: calc(var(--preloader-size)/-2);
      background-color: #000;
      border-radius: calc(var(--preloader-size)/2);
      transform: scale(0);
    }

    &:before {
      background-color: var(--primary-color-opacity, var(--primary-color));
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: var(--primary-color);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
.review-main {
  position: relative;
  margin-bottom: 170px;

  @include respond-down(M){
    margin-bottom: 90px;
  }

  @include respond(XS){
    margin-bottom: 132px;
  }

  &__title-row {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;

    @include respond-down(L){
      margin-bottom: 35px;
    }

    @include respond-down(M){
      margin-bottom: 30px;
    }

    @include respond-down(S){
      margin-bottom: 24px;
    }
  }

  &__inner-row {
    flex: 1 1 auto;
    padding-right: 30px;
  }

  &__title {
    display: block;
    margin-bottom: 24px;
    font-size: 38px;
    font-weight: bold;
    line-height: var(--title-line-height);

    @include respond-down(M){
      font-size: 22px;
      margin-bottom: 16px;
    }

    @include respond-down(S){
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  &__title-text {

  }

  &__description {
    display: block;
    font-size: 16px;

    @include respond-down(M){
      font-size: 14px;
    }

    @include respond-down(S){
      font-size: 12px;
    }
  }

  &__wrapper-button-row {
    flex: 0 0 auto;

    @include respond(XS){
      position: absolute;
      bottom: -50px;
      width: 100%;
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;


    .review-preview{
      flex: 0 0 49%;
    }

    @include respond-down(M){
      flex-wrap: wrap;

      .review-preview{
        flex: 0 0 100%;
      }
    }
  }
}
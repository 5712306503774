.advantages {
  margin-bottom: 160px;

  @include respond-down(M){
    margin-bottom: 96px;
  }

  @include respond(XS){
    margin-bottom: 67px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__slider-panel {
    display: flex;
    padding-top: 28px;

    @include respond-down(M) {
      display: none;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 24px;

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 16px;
    }

    @include respond(XS) {
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    margin-bottom: 24px;

    @include respond-down(M) {
      font-size: 14px;
      margin-bottom: 34px;
    }

    @include respond(XS) {
      font-size: 12px;
      margin-bottom: 26px;
    }
  }

  &__prev {
    margin-right: 8px;
  }

  &__list {
    display: block;
  }

  &__item {
    display: block;
  }

  &__inner {
    border-radius: 16px 0;
    background-color: var(--col_yellow);
    overflow: hidden;
  }

  &__image {
    padding-top: 58%;

    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    };

    img {
      display: none;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    height: 106px;
    padding: 30px;

    @include respond-down(L) {
      font-size: 16px;
    }

    @include respond-down(M) {
      height: 88px;
    }

    @include respond-down(XS) {
      font-size: 12px;
      height: 72px;
    }
  }
}
.breadcrumbs {
  &__list {
    display: flex;
  }

  &__item {
    &_delimiter {
      padding: 0 5px;
      margin-top: 2px;
    }

    &:last-child {
      color: var(--col_grey);
    }
  }

  &__link {
    font-size: 12px;
  }

  &__name {
    font-size: 12px;
  }
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
}

h1, .h1 {
  @include respond-up(L) {
    --h-size: 28px;
  }

  @include respond-down(M) {
    --h-size: 22px;
  }
}

h2, .h2 {
  @include respond-up(L) {
    --h-size: 28px;
  }

  @include respond-down(M) {
    --h-size: 22px;
  }
}

h3, .h3 {
  --h-size: 26px;
}

h4, .h4 {
  --h-size: 24px;
}

h5, .h5 {
  --h-size: 22px;
}

h6, .h6 {
  --h-size: 20px;
}
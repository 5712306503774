.sticker {
  display: flex;
  position: absolute;
  padding: 14px 29px 17px 58px;
  border-radius: 0 16px 16px 0;
  background: var(--col_yellow);
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;

  @include respond-down(L){
    padding: 8px 16px 9px 27px;
  }

  @include respond-down(M) {
    border-radius: 0 8px 8px 0;
    padding: 12px 17px 12px 49px;
  }

  @include respond(XS) {
    padding: 3px 11px 6px 29px;
    font-size: 12px;
  }

  &:after {
    position: absolute;
    height: 2px;
    display: block;
    width: 68px;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: -33px;
    background: black;

    @include respond-down(L){
      width: 43px;
      left: -25px;
    }

    @include respond(M) {
      width: 57px;
      left: -28px;
    }

    @include respond(XS) {
      width: 32px;
      left: -18px;
    }
  }

  &_kitchen {
    @include respond-up(L) {
      font-size: 18px;
      border-radius: 0 8px 8px 0;
    }

    @include respond-down(M) {
      border-radius: 0;
      padding: 14px 29px 17px 58px;
    }

    @include respond(XS) {
      padding: 8px 14px 9px 36px;
    }

    &:after {
      @include respond(M) {
        width: 68px;
        left: -33px;
      }

      @include respond(XS) {
        width: 46px;
        left: -23px;
      }
    }
  }
}
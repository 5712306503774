body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);

  &._fix{
    overflow: hidden;
  }
}

input, button, form {
  font-family: var(--font);
}

.article {
  font-size: 16px;

  @include respond-down(S){
    font-size: 12px;
  }

  h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: var(--title-line-height);
    margin-bottom: 16px;

    @include respond-down(S) {
      margin-bottom: 8px;
      font-size: 18px;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: var(--title-line-height);
    margin-bottom: 8px;

    @include respond-down(S) {
      margin-bottom: 4px;
      font-size: 12px;
    }
  }

  p {
    display: block;
    margin-bottom: 25px;

    @include respond-down(M){
      margin-bottom: 15px;
    }
  }

  strong {
    font-weight: bold;
  }

  li {
    list-style-type: none;
    position: relative;
  }

  ol, ul {
    padding-left: 50px;
    margin-bottom: 25px;
    counter-reset: list1;
  }

  ol li:before {
    counter-increment: list1;
    content: counter(list1) ".";
    position: absolute;
    left: -18px;
    transform: translate(-100%);
  }

  ul li:before {
    content: '';
    display: block;
    border-radius: 50%;
    background: var(--col_yellow);
    height: 8px;
    width: 8px;
    position: absolute;
    left: -18px;
    top: 9px;
    transform: translate(-100%);
  }
}

// Красный индикатор разрешений ( в углу )
#DEBUG {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;

  &:after {
    content: 'XL';
    @include respond-down(L) {
      content: 'L';
    }
    @include respond-down(M) {
      content: 'M';
    }
    @include respond-down(S) {
      content: 'S';
    }
    @include respond-down(XS) {
      content: 'XS';
    }
  }
}


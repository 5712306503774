.error-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 465px;

  @include respond-down(M) {
    height: 323px;
  }

  @include respond(XS) {
    height: 198px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 107px;

    @include respond-down(M) {
      margin-bottom: 103px;
    }

    @include respond(XS) {
      margin-bottom: 70px;
    }
  }

  &__background-inner {
    position: absolute;
    line-height: 0.8;
    top: 0;
    left: 0;
    right: 0;
    font-weight: bold;
    color: var(--col_main-grey);
    opacity: 0.5;
    text-shadow: -12px -12px 24px var(--col_main-white), 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 500px;
    text-align: center;

    @include respond(L) {
      line-height: 0.9;
      font-size: 460px;
    }

    @include respond(M) {
      font-size: 300px;
    }

    @include respond(S) {
      line-height: 1;
      font-size: 260px;
    }

    @include respond(XS) {
      font-size: 130px;
      line-height: 1;
    }

  }

  &__text {
    opacity: 0.9;
  }

  &__title {
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;

    @include respond-down(M) {
      font-size: 36px;
      line-height: 44px;
    }

    @include respond(XS) {
      font-size: 19px;
      line-height: 23px;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;

    @include respond-down(M) {
      font-size: 22px;
      line-height: 27px;
    }

    @include respond(XS) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__button {
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
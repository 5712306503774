.offer {
  display: block;
  margin-bottom: 160px;

  @include respond-down(L) {
    margin-bottom: 120px;
  }

  @include respond-down(M) {
    margin-bottom: 96px;
  }

  @include respond-down(S) {
    margin-bottom: 36px;
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__video-wrapper-outer {
    @include respond-up(M) {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
    }

    @include respond-down(S) {
      margin-right: -20px;
      margin-left: -20px;
    }
  }

  &__video-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;

    @include respond-up(XL) {
      height: 680px;
      width: 680px;
    }

    @include respond(L) {
      height: 500px;
      width: 500px;
    }

    @include respond(M) {
      height: 500px;
    }

    @include respond-down(S) {
      max-height: 1920px;
      //height: auto;
      //width: calc(100vw - 40px);
    }
  }

  &__list {
    display: flex;

    @include respond-down(S) {
      min-height: calc(100vh - 145px);
    }
  }

  &__item {
    display: block;
    overflow: hidden;

    .half-container {
      height: 100%;

      @include respond-down(S) {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__inner {
    display: flex;
    min-height: 650px;
    height: 100%;

    @include respond-down(L) {
      min-height: 500px;
    }

    @include respond-down(M) {
      min-height: 400px;
    }

    @include respond-down(S) {
      min-height: auto;
      flex-direction: column-reverse;
      justify-content: stretch;
    }
  }

  &__wrapper {
    padding: 4% 3% 0 0;

    @include respond-down(S) {
      padding: 0;
    }

    .container {
      height: 100%;

      @include respond-up(M) {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 65px;
    line-height: 1.4;

    @include respond-down(L) {
      font-size: 50px;
      line-height: 1.3;
      margin-bottom: 45px;
    }

    @include respond-down(M) {
      margin-bottom: 40px;
      font-size: 36px;
      line-height: 1.5;
    }

    @include respond-down(S) {
      margin-top: 40px;
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 1.5;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    height: 70px;
    position: relative;
    z-index: 1;
    left: calc(((var(--max-row-width) * 1px) - 100vw) / 2 - 40px);
    padding-left: calc((100vw - ((var(--max-row-width) * 1px))) / 2 + 40px);
    width: fit-content;
    padding-right: 56px;
    border-radius: 0 16px 16px 0;
    background: linear-gradient(106.61deg, #FDBD5E 100%, #F2CA6E 100%);
    box-shadow: -6px -6px 12px #FFFFFF, 6px 6px 12px #D6D6D6, inset 4px 4px 20px #F2CA6E;
    margin-bottom: 90px;
    font-weight: bold;
    font-size: 20px;

    @media screen and (max-width: 1713px) {
      left: -40px;
      padding-left: 40px;
    }

    @include respond-up(L) {
      transition: var(--default-transition);

      &:hover {
        transition: var(--default-transition);
        background: linear-gradient(106.61deg, #FDBD5E 0%, #F2CA6E 100%);

        .offer__button-arrow {
          transition: var(--default-transition);
          right: -41px;
        }
      }
    }

    @include respond-down(L) {
      left: calc(((var(--max-row-width) * 1px) - 100vw) / 2 - 30px);
      padding-left: calc((100vw - ((var(--max-row-width) * 1px))) / 2 + 30px);
      margin-bottom: 70px;
    }

    @media screen and (max-width: 1310px) {
      left: -30px;
      padding-left: 30px;
    }

    @include respond-down(M) {
      margin-bottom: 50px;
      border-radius: 0 8px 8px 0;
      font-size: 14px;
      padding-right: 53px;
      height: 42px;
    }

    @include respond-down(S) {
      left: -20px;
      padding-left: 20px;
      padding-right: 38px;
    }

    @include respond(XS) {
      font-size: 12px;
      padding-right: 33px;
      margin-bottom: 0;
    }
  }

  &__button {
    white-space: nowrap;
  }

  &__button-arrow {
    transition: var(--default-transition);
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    right: -31px;
    z-index: 2;

    @include respond-down(S) {
      width: 40px;
      right: -18px;
      overflow: hidden;
      height: 10px;

      svg {
        position: absolute;
        right: 0;
      }
    }
  }

  &__description {
    display: block;
    text-align: right;

    @include respond-down(M) {
      font-size: 14px;
    }

    @include respond-down(S) {
      font-size: 12px;
    }
  }

  &__image {
    border-radius: 16px 0px 0px 16px;
    width: 100%;
    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    };

    img {
      display: none;
    }

    @include respond-down(S) {
      padding-top: 44%;
      height: 100%;
      border-radius: 0;
      margin-bottom: 23px;
    }
  }

  &__slider-panel {
    padding-top: 32px;
    display: flex;
    justify-content: flex-end;

    @include respond-down(M) {
      padding-top: 18px;
    }
  }

  &__prev {
    margin-right: 8px;
  }

  &__next {

  }

  .tns-outer {
    position: relative;
  }

  .tns-nav {
    position: absolute;
    bottom: -55px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);

    @include respond-down(M) {
      left: 430px;
      bottom: -40px;
      transform: none;
    }

    @include respond-down(S) {
      left: 20px;
    }


    button {
      padding: 4px;
      position: relative;
      display: block;
      width: 24px;
      height: 20px;
      border: none;
      background: transparent;

      @include respond-down(M) {
        width: 20px;
        height: 16px;
      }

      &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: linear-gradient(92.29deg, #F0F0F0, #E5E5E5, #D6D6D6);
        background-size: 200%;
        box-shadow: -2px -2px 4px #FFFFFF, 2px 2px 4px #E5E5E5;
        border-radius: 4px;
        transition: var(--default-transition);

        @include respond-down(M) {
          width: 12px;
          height: 12px;
        }
      }


      @include respond-up(L) {
        &:hover {
          &:after {
            transition: var(--default-transition);
            background-position: 100%;
          }
        }
      }

      &.tns-nav-active {
        &:after {
          transition: var(--default-transition);
          background: linear-gradient(92.29deg, #FDBD5E 50%, #F2CA6E 100%);
          background-size: 200%;
        }

        @include respond-up(L) {
          &:hover {
            &:after {
              transition: var(--default-transition);
              background-position: 100%;
            }
          }
        }
      }
    }
  }
}

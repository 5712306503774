.faqs {
  margin-bottom: 48px;

  @include respond-down(M) {
    margin-bottom: 16px;
  }

  @include respond(XS) {
    margin-bottom: 32px;
  }

  &__jaliswall {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 0 -12px;

    @include respond(XS) {
      visibility: hidden;
      position: absolute;
      width: 0;
    }
  }

  &__jaliswall-column {
    display: block;
    position: relative;
    flex: 0 0 50%;
    width: 50%;
    padding: 0 12px;

    @include respond-down(M) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  &__slider {
    display: block;
    position: relative;

    @include respond-up(S) {
      visibility: hidden;
      position: absolute;
      width: 0;
      height: 0;
    }

    .tns-nav {
      position: absolute;
      left: 16px;
      top: 14px;
      transform: translateY(-50%);
      display: flex;


      button {
        padding: 4px;
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border: none;
        background: transparent;

        &:after{
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          background: linear-gradient(92.29deg, #E5E5E5 0%, #D6D6D6 100%);
          box-shadow: -2px -2px 4px #FFFFFF, 2px 2px 4px #E5E5E5;
          border-radius: 4px;
        }

        &.tns-nav-active{
          &:after{
            background: linear-gradient(92.29deg, #FDBD5E 0%, #F2CA6E 100%);
          }
        }
      }
    }
  }

  &__slider-panel {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 33px;
  }

  &__slider-prev {
    margin-right: 8px;
  }

  &__slider-list {
    display: block;
  }
}
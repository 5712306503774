.header {
  left: 0;
  right: 0;
  z-index: 5;
  transition: top .7s;
  background: #F0F0F0;
  padding-top: 0;
  top: -162px;
  position: absolute;
  box-shadow: 0 2px 4px rgba(121, 121, 121, 0.2);

  .header__wrapper-logo {
    justify-content: center;
  }

  .header__wrapper-menu {
    padding-top: 0;
  }

  .header__wrapper-contacts {
    padding-top: 0;
  }

  &._visible {
    top: 0;
  }

  &._transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &._fixed {
    position: fixed;

    .header__logo {
      margin-bottom: 0;

      svg {
        height: 32px;
        width: 150px;
      }

      @include respond(XS) {
        svg {
          height: 15px;
          width: 72px;
        }
      }
    }

    .header__logo-title {
      display: none;
    }

    .header__container {
      padding: 13px 0 17px;

      @include respond-up(XL) {
        &:after {
          padding: 8px 0;
        }
      }
    }

    .header__menu-link {
      @include respond-up(L) {
        &:after {
          bottom: 8px;
        }
      }

      @include respond-up(XL) {
        &:after {
          bottom: 2px;
        }
      }
    }
  }

  &._open {
    transition: height 1s;
    height: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-up(L) {
      height: 170px;
    }
    @include respond(M) {
      padding-top: 15px;
    }
    @include respond(S) {
      padding-top: 20px;
    }
    @include respond-down(XS) {
      padding-top: 12px;
    }
  }

  &__wrapper-logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include respond-up(XL) {
      margin-right: 20px;
    }
  }

  &__city,
  &__link-text {
    @include respond-up(XL) {
      font-size: 14px;
    }
    @include respond-down(L) {
      font-size: 13px;
    }
  }

  &__logo {
    display: flex;

    svg {
      display: flex;
      height: auto;
    }

    @include respond-up(XL) {
      svg {
        width: 150px;
      }
    }
    @include respond(L) {
      svg {
        width: 100px;
      }
    }
    @include respond(M) {
      svg {
        width: 150px;
      }
    }

    @include respond-down(S) {
      svg {
        width: 72px;
      }
    }
  }

  &__logo-title {
    display: flex;
    flex-wrap: nowrap;
    line-height: 1;
    letter-spacing: 0.03em;
    margin-top: 5px;

    @include respond-up(XL) {
      font-size: 14px;
    }
    @include respond(L) {
      font-size: 10px;
    }
    @include respond(M) {
      font-size: 12.5px;
    }

    @include respond-down(S) {
      font-size: 6px;
    }
  }

  &__wrapper-menu {
    display: flex;
    //flex-wrap: wrap;
  }

  &__menu-link {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;

    @include respond-up(XL) {
      padding: 0 8px;
      &:after {
        bottom: 60px;
      }
    }

    @include respond(L) {
      padding: 0 6px;
    }
    @include respond-up(L) {
      &:after {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translate(-50%, 50%);
        height: 0;
        width: 0;
        border-radius: 50%;
        content: '';
        background: var(--col_yellow);
        box-shadow: -2px -2px 4px 0px #FFFFFF, 2px 2px 4px 0px #E5E5E5;
        transition: var(--default-transition);
      }

      &:hover:after {
        height: 8px;
        width: 8px;
        transition: var(--default-transition);
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: center;
    padding: 0 10px;
    flex: 1 0 auto;

    @include respond-down(M) {
      display: none;
    }
  }

  &__wrapper-contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
    flex-wrap: wrap;
    @include respond-up(L) {
      height: 100px;
    }
  }

  &__city {
    @include respond-down(L) {
      font-size: 14px;
    }

    @include respond(L) {
      display: none;
    }

    @include respond-down(S) {
      display: none;
    }
  }

  &__phone {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @include respond(XL) {
      padding: 0 20px;
    }

    @include respond-down(L) {
      height: auto;
      font-size: 18px;
    }

    @include respond-down(M) {
      display: none;
    }
  }

  &__wrapper-button {
    flex: 0 0 auto;

    @include respond(L) {
      margin-left: 30px;
    }

    @include respond-down(M) {
      margin-right: 28px;
      margin-left: 38px;
    }

    @include respond-down(XS) {
      margin-right: 8px;
    }
  }

  &__inner-contacts {
    //height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    //@include respond-down(L) {
    //  flex-direction: column;
    //  justify-content: center;
    //  align-items: flex-end;
    //}

    @include respond-down(S) {
      flex: 1 1 auto;
    }
  }

  &__button-burger {
    display: none;

    @include respond-down(M) {
      display: flex;
      flex: 0 0 40px;
    }

    @include respond(XS) {
      flex: 0 0 30px;
    }
  }

  &__button-recall {
    @include respond-down(M) {
      display: none;
    }
  }

  &__button-call {
    display: none;

    @include respond-down(M) {
      display: flex;
    }
  }
}
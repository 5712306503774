.faq-form {
  @include respond-down(S) {
    .container {
      padding: 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--col_main-grey);
    border: 1px solid var(--col_main-border);
    padding: 96px 8%;

    @include respond-down(M) {
      padding: 48px;
    }

    @include respond(S) {
      align-items: center;
    }

    @include respond(XS) {
      padding: 48px 24px;
    }
  }

  &__title {
    @include respond(S) {
      width: 330px;
    }
  }

  &__wrapper-fields {
    display: flex;
    flex-direction: column;
    width: 330px;

    @include respond(XS) {
      width: auto;
    }
  }

  &__title {
    display: block;
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 24px;

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    display: block;
    font-size: 24px;
    margin-bottom: 65px;

    @include respond-down(M) {
      font-size: 16px;
      margin-bottom: 36px;
    }

    @include respond(XS) {
      margin-bottom: 20px;
    }
  }

  &__field-phone {
    margin-bottom: 24px;

    @include respond(XS) {
      margin-bottom: 20px;
    }
  }

  &__field-question {
    margin-bottom: 32px;

    @include respond(XS) {
      margin-bottom: 24px;
    }
  }

  &__button {
    margin-bottom: 24px;

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__background {
    display: block;
    position: absolute;
    top: 50%;
    left: 57%;
    transform: translateY(-50%);
    width: 431px;
    height: 577px;

    @include respond-down(L) {
      width: 345px;
      height: 462px;
    }

    @include respond-down(M) {
      width: 248px;
      height: 333px;
    }

    @include respond-down(S) {
      display: none;
    }
  }
}
.seo {
  &__content{
    display: flex;
    margin-bottom: 100px;

    @include respond-down(M){
      flex-wrap: wrap;
      margin-bottom: 60px;
    }

    @include respond-down(S){
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
  }

  &__image {
    flex: 0 0 45%;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    };

    @include respond-down(M){
      flex: 0 0 100%;
      padding-top: 50%;
      margin-bottom: 40px;
    }

    @include respond-down(S){
      display: none;
    }
  }

  &__text {
    padding-top: 20px;
    padding-left: 5%;
    flex: 0 0 50%;

    @include respond-down(M){
      flex: 0 0 100%;
      padding-left: 0;
    }
  }
}
.process {
  position: relative;
  z-index: 1;
  margin-bottom: 120px;

  @include respond-down(M){
    margin-bottom: 96px;
  }

  @include respond-down(XS){
    margin-bottom: 14px;
  }

  &:after{
    position: absolute;
    left: 0;
    right: 0;
    top: 64%;
    transform: translateY(-50%);
    display: block;
    height: 8px;
    content: '';
    background: var(--col_yellow);
    z-index: 2;

    @include respond-down(M){
      top: 55%;
    }
  }

  &__inner{

  }
  &__title-row {
    margin-bottom: 64px;

    @include respond-down(L){
      margin-bottom: 50px;
    }

    @include respond-down(M){
      margin-bottom: 0px;
    }

    @include respond-down(S){
      margin-bottom: 0px;
    }
  }

  &__title {
    display: block;
    font-size: 38px;
    font-weight: bold;

    @include respond-down(M){
      font-size: 22px;
    }

    @include respond-down(S){
      font-size: 20px;
    }
  }

  &__wrapper-list{



    @include respond-down(M){
      margin: 0 -30px;
      height: 292px;
      overflow: hidden;
    }

    @include respond-down(S){
      margin: 0 -20px;
    }
  }



  &__list {
    position: relative;z-index: 3;
    display: flex;
    justify-content: space-between;

    @include respond-down(L){

    }
    @include respond-down(M){
      padding: 40px 20px 80px;
      overflow: scroll;

    }
  }

  &__item {
    flex: 0 0 15%;
    padding: 33px;
    display: flex;
    justify-content: center;
    background: #F0F0F0;
    box-shadow: -12px -12px 24px #FFFFFF, 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    flex-wrap: wrap;
    align-items: flex-start;

    transition: transform 500ms ease-out;

    @include respond-down(L){
      padding: 20px;
    }

    @include respond-down(M){
      flex: 0 0  242px;
      margin: 0 8px;

      &:last-child{
        margin-right: 30px;
      }
    }
  }

  &__wrapper-svg {
    margin-bottom: 46px;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    height: 120px;

    @include respond-down(L){
      height: 90px;
      margin-bottom: 30px;

      svg{
        width: 80px;
      }
    }
  }

  &__text{
    font-weight: bold;
    flex: 0 0 100%;
    padding: 0 20px;
    text-align: center;

    @include respond-down(L){
      padding: 0px;
      font-size: 14px;

    }
  }
}

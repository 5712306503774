.projects-page {
  &__project {
    .h2 {
      margin-top: 40px;
    }
  }

  &__images-wrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
  }
}
.kitchen-form {
  padding-left: 40px;
  padding-bottom: 40px;
  max-width: calc((100vw - var(--max-row-width) * 1px) / 2 + var(--max-row-width) * 1px);
  margin-left: auto;
  transition: var(--default-transition);

  @include respond-down(L) {
    padding-left: 30px;
  }

  @include respond-down(M) {
    padding-left: 0;
    max-width: none;
  }

  &.success {
    .kitchen-form__image {
      opacity: 0;
      transition: var(--default-transition);
    }
  }

  &__background {
    position: relative;
    padding: 96px 130px;
    display: flex;
    justify-content: flex-start;
    border: 1px solid var(--col_main-border);
    background-color: var(--col_main-grey);

    @media screen and (max-width: 1920px) {
      flex-direction: column;
    }

    @include respond-down(M) {
      padding: 48px 30px;
    }

    @include respond(S) {
      align-items: center;
    }

    @include respond-down(XS) {
      padding: 48px 20px;
    }
  }

  &__wrapper-info {
    margin-bottom: 57px;
    margin-right: 100px;

    @media screen and (max-width: 1920px) {
      margin-right: 0;
    }

    @include respond(L) {
      margin-bottom: 52px;
      width: 66%;
    }

    @include respond-down(M) {
      margin-bottom: 36px;
    }

    @include respond(S) {
      width: 330px;
    }

    @include respond-down(XS) {
      margin-bottom: 21px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 24px;

    @include respond-down(L) {
      font-size: 35px;
      margin-bottom: 22px;
    }

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 8px;
    }
  }

  &__text {
    font-size: 24px;

    @include respond-down(L) {
      font-size: 22px;
    }

    @include respond-down(M) {
      font-size: 16px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1920px) {
      flex-direction: row;
    }

    @include respond-down(L) {
      flex-direction: column;
    }
  }

  &__wrapper-fields {
    display: flex;
    flex-direction: column;
    width: 330px;
    margin-bottom: 42px;

    @media screen and (max-width: 1920px) {
      margin-bottom: 0;
      margin-right: 32px;
    }

    @include respond-down(L) {
      margin-bottom: 46px;
      margin-right: 0;
    }

    @include respond-down(XS) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__field-phone {
    margin-bottom: 24px;

    @include respond-down(XS) {
      margin-bottom: 21px;
    }
  }


  &__wrapper-buttons {
    display: flex;
    flex-direction: column;
    width: 330px;

    @media screen and (max-width: 1920px) {
      padding-top: 20px;
    }

    @include respond-down(L) {
      padding-top: 0;
    }

    @include respond-down(XS) {
      width: 100%;
    }
  }

  &__button-upload {
    margin-bottom: 42px;

    @media screen and (max-width: 1920px) {
      margin-bottom: 44px;
    }

    @include respond-down(L) {
      margin-bottom: 42px;
    }

    @include respond-down(XS) {
      margin-bottom: 24px;
    }
  }

  &__button-recall {
    margin-bottom: 24px;

    @include respond-down(XS) {
      margin-bottom: 16px;
    }
  }

  &__image {
    position: absolute;
    left: 1420px;
    bottom: -64px;
    width: 800px;
    height: 800px;

    @media screen and (max-width: 1920px) {
      width: 770px;
      height: 770px;
      left: calc(450px + 32%);
    }

    @include respond-down(L) {
      bottom: 0;
      left: calc(250px + 32%);
    }

    @include respond-down(M) {
      width: 530px;
      height: 530px;
      left: 52%;
    }

    @include respond-down(S) {
      display: none;
    }
  }
}

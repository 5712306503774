.reasons {
  overflow: hidden;
  margin-bottom: 96px;

  @include respond-down(M) {
    margin-bottom: 64px;
  }

  @include respond(XS) {
    margin-bottom: 72px;
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 64px;

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 48px;
    }

    @include respond(XS) {
      margin-bottom: 30px;
    }
  }

  &__outer {
    padding-bottom: 30px;
    overflow-x: scroll;

    @include respond-down(M) {
      .container {
        padding: 0;
      }
    }
  }

  &__overflow {
    overflow: hidden;
    margin-bottom: -30px;
  }

  &__list {
    display: flex;

    @include respond-down(M) {
      width: 1166px;
    }

    @include respond(XS) {
      width: 956px;
    }
  }

  &__item {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    margin-bottom: 46px;

    @include respond-down(L) {
      margin-bottom: 32px;
      svg {
        height: 120px;
      }
    }

    @include respond(XS) {
      margin-bottom: 21px;
      svg {
        height: 105px;
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 220px;
    font-size: 20px;
    text-align: center;

    @include respond-down(L) {
      width: 180px;
      font-size: 16px;
    }

    @include respond-down(M) {
      width: 210px;
    }

    @include respond(XS) {
      width: 190px;
      font-size: 14px;
    }
  }
}
.stock-form {
  padding: 60px 0;
  margin: -60px 0;
  overflow: hidden;

  &__inner {
    position: relative;
    padding: 96px 0 72px 11.3%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--col_main-border);
    background-color: var(--col_main-grey);

    @include respond-down(M) {
      padding: 48px 46px;
    }

    @include respond(XS) {
      padding: 48px 24px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 24px;

    @include respond-down(M) {
      display: none;
    }
  }

  &__title-hidden {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 8px;
    display: none;

    @include respond-down(M) {
      display: inline;
    }
  }

  &__subtitle {
    font-size: 24px;
    margin-bottom: 57px;

    @include respond-down(M) {
      display: none;
    }
  }

  &__subtitle-hidden {
    margin-bottom: 36px;
    display: none;

    @include respond-down(M) {
      display: inline;
    }

    @include respond(XS) {
      margin-bottom: 21px;
    }
  }

  &__container {
    display: flex;
    transition: var(--default-transition);

    @include respond-down(M) {
      flex-direction: column;
    }

    &.success {
      .stock-form__image {
        opacity: 0;
        transition: var(--default-transition);
      }
    }
  }

  &__wrapper-left {
    display: flex;
    flex-direction: column;
    width: 330px;
    margin-right: 32px;

    @include respond(XS) {
      width: 100%;
    }
  }

  &__wrapper-right {
    display: flex;
    flex-direction: column;
    width: 330px;

    @include respond(XS) {
      width: 100%;
    }
  }

  &__field-name {
    margin-bottom: 24px;

    @include respond(XS) {
      margin-bottom: 21px;
    }
  }

  &__field-phone {
    margin-bottom: 24px;
  }

  &__field-email {
    @include respond-down(M) {
      margin-bottom: 40px;
    }

    @include respond(XS) {
      margin-bottom: 24px;
    }
  }

  &__field-address {
    margin-bottom: 44px;

    @include respond-down(M) {
      display: none;
    }
  }

  &__field-upload {
    margin-bottom: 44px;

    @include respond-down(M) {
      margin-bottom: 40px;
    }

    @include respond(XS) {
      margin-bottom: 24px;
    }
  }

  &__button {
    margin-bottom: 24px;

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: -59px;
    width: 745px;
    height: 798px;
    opacity: 1;
    transition: var(--default-transition);

    @media screen and (max-width: 1920px) {
      left: calc(25% + 600px);
    }

    @include respond-down(M) {
      width: 440px;
      height: 471px;
      bottom: 80px;
      left: calc(37% + 210px);
    }

    @include respond(XS) {
      display: none;
    }
  }
}
.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  background: {
    color: #f5f5f5;
    image: url("/static/images/background/bg-main1.jpg");
    repeat: repeat;
    position: 0 0;
  };

  &__wrapper {
    overflow: hidden;

    @include respond-up(XL) {
      padding: 200px 0 70px;
    }

    @include respond(L) {
      padding: 160px 0 60px;
    }

    @include respond(M) {
      padding: 90px 0 40px;
    }

    @include respond(S) {
      padding: 90px 0 30px;
    }

    @include respond-down(XS) {
      padding: 60px 0 30px;
    }
  }
}
.team {
  overflow: hidden;
  margin-bottom: 103px;

  @include respond-down(M) {
    margin-bottom: 55px;
  }

  @include respond(XS) {
    margin-bottom: 51px;
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 34px;

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 18px;
    }

    @include respond(XS) {
      margin-bottom: 0;
    }
  }

  &__outer {
    padding-bottom: 30px;
    overflow-x: scroll;

    @include respond-down(M) {
      .container {
        padding: 0;
      }
    }
  }

  &__overflow {
    overflow: hidden;
    margin-bottom: -30px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

    padding: 30px;
    margin: 0 -30px;

    @include respond-down(M) {
      width: 1629px;
      margin: 0;
    }

    @include respond-down(S) {
      width: 1629px;
      margin: 0 -10px;
    }

    @include respond(XS) {
      padding: 30px 20px;
      margin: 0;
      width: 1000px;
    }
  }

  &__item {
    display: flex;
    background: var(--col_main-grey);
    box-shadow: -12px -12px 24px #FFFFFF, 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px 0;
  }

  &__image {
    flex-basis: 37.5%;
    padding-top: 37.5%;
    display: block;
    border-radius: 16px 0;

    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    };

    img {
      display: none;
    }
  }

  &__info {
    flex-basis: 63.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    font-size: 18px;

    @include respond-down(L) {
      font-size: 15px;
    }

    @include respond-down(M) {
      font-size: 20px;
    }

    @include respond(XS) {
      font-size: 12px;
    }
  }

  &__name {
    font-weight: bold;
    line-height: 1.3;
  }

  &__team-position {
    color: #858585;
  }
}
.catalog {
  &__item {
    &_equal {
      picture {
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        height: 260px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: unset;
          min-height: 260px;
        }
      }
    }
  }
}
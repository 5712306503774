.catalog-kitchen {
  display: grid;
  grid-template-columns: 350px auto;

  @include respond-down(L) {
    grid-template-columns: 300px auto;
  }

  @include respond-down(M) {
    grid-template-columns: 1fr;
  }

  &__wrapper-sidebar {
    overflow: hidden;
  }

  &__list {
    padding-left: 4%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    @include respond-up(L) {
      grid-gap: 38px 25px;
    }

    @include respond(M) {
      grid-gap: 50px 17px;
      padding-left: 0;
    }

    @include respond(S) {
      grid-gap: 50px 17px;
    }

    @include respond-down(XS) {
      grid-gap: 40px 0;
    }
  }
}
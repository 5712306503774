.part-row {
  display: flex;
  margin-bottom: 30px;
  align-items: flex-start;

  @include respond-down(L){
    margin-bottom: 20px;
  }
  @include respond-down(M){
    margin-bottom: 20px;
  }
  @include respond-down(S){
    margin-bottom: 10px;
  }

  &_one{

  }

  &_double{
    .part-row__img{
      flex: 0 0 40%;
      margin-right: 2%;
      padding-top: 20%;
    }

    @include respond-down(S){
      flex-wrap: wrap;

      .part-row__img{
        margin-bottom: 10px;
        flex: 0 0 100%;
        margin-right: 5%;
        padding-top: 40%;
      }
    }

  }

  &__text {
    flex: 1 1 100%;

  }

  &__img {
    flex: 1 1 100%;
    display: flex;
    padding-top: 40%;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    }

    img {
      display: none;
    }
  }
}
.kitchen {
  display: flex;
  background-color: white;

  @include respond-up(L){
    padding-bottom: 58px;
  }

  @include respond-down(M) {
    flex-direction: column;

    .fotorama__nav-wrap {
      display: none;
    }
  }

  &__sale-block {
    top: 50px;
    right: -14px;

    @include respond-down(M) {
      top: 25px;
      right: 48px;
    }

    @include respond(XS) {
      top: 20px;
      right: 35px;
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 55%;

    @include respond-down(M) {
      width: 100%;
    }
  }

  &__info {
    max-width: 750px;
    padding: 72px 67px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;

    @include respond-down(L) {
      font-size: 16px;
      padding: 58px 54px 0;
    }

    @include respond-down(M) {
      max-width: none;
      font-size: 14px;
      padding: 21px 30px 64px;
    }

    @include respond-down(S) {
      padding: 23px 20px 32px;
    }

    @include respond(XS) {
      align-items: stretch;
      font-size: 14px;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 40px;

    @include respond-down(L) {
      font-size: 30px;
      margin-bottom: 32px;
    }

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 24px;
    }

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__properties {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;

    @include respond-down(L) {
      margin-bottom: 26px;
    }

    @include respond-down(M) {
      margin-bottom: 32px;
    }

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__wrapper-price {
    margin-bottom: 18px;
    display: flex;
    align-items: center;

    @include respond(L) {
      margin-bottom: 16px;
    }

    @include respond-down(M) {
      margin-bottom: 34px;
    }

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__price {
    position: relative;
    z-index: 1;

    &_new {
      font-size: 32px;
      font-weight: bold;
      margin-right: 31px;

      @include respond(L) {
        font-size: 26px;
        margin-right: 25px;
      }

      @include respond-down(M) {
        font-size: 22px;
        margin-right: 20px;
      }
    }

    &_old {
      padding: 0 6px;
      font-size: 20px;
      color: #000000;


      @include respond(L) {
        font-size: 16px;
      }

      @include respond-down(M) {
        font-size: 14px;
      }

      &:after {
        content: '';
        z-index: 2;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        background: var(--col_yellow);
        height: 2px;
        left: 0;
        right: 0;
        display: block;
      }
    }
  }

  &__button {
    margin-bottom: 48px;

    @include respond(L) {
      margin-bottom: 38px;
    }

    @include respond-down(M) {
      margin-bottom: 30px;
    }

    @include respond(XS) {
      margin-bottom: 16px;
    }
  }

  &__button-text {
    display: block;

    @include respond-down(M) {
      display: none;
    }

    &_little {
      display: none;

      @include respond-down(M) {
        display: block;
      }
    }
  }

  &__text {
    padding-top: 50px;
    margin-bottom: 50px;
  }

  .fotorama__stage {
    margin-bottom: 9px;
  }

  .fotorama__thumb-border {
    border-width: 0 !important;
    width: 128px !important;
    height: 128px !important;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 4px;
      background: var(--primary-color);
      bottom: -12px;
    }
  }
}
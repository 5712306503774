.materials {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px 24px;

    @include respond-down(L) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include respond-down(M) {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-down(S) {
      grid-gap: 16px;
    }

    @include respond-down(XS) {
      grid-template-columns: 1fr;
    }
  }
}
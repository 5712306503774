.kitchen-materials {
  background-color: var(--col_main-grey);
  border: 1px solid var(--col_main-border);
  padding: 64px 0 32px;
  margin-bottom: 80px;
  overflow: hidden;

  @include respond-down(M){
    margin-bottom: 0;
    padding: 48px 0 38px;
  }

  @include respond(XS){
    padding: 48px 0 7px;
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 24px;

    @include respond-down(M){
      font-size: 22px;
      margin-bottom: 16px;
    }
  }

  &__text {
    display: block;
    margin-bottom: 16px;

    @include respond-down(M){
      margin-bottom: 8px;
    }

    @include respond(XS){
      margin-bottom: 0;
    }
  }

  &__scrollbar-hide {
    overflow: hidden;
  }

  &__scroll-area {
    max-width: calc(var(--max-row-width) * 1px);
    margin: 0 auto -15px;
    overflow-x: scroll;
  }

  &__list {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
    padding: 31px 40px;

    @include respond-down(L){
      padding: 31px 30px;
    }

    @include respond-down(M){
      grid-template-columns: auto auto;
      grid-gap: 16px;
    }

    @include respond(XS){
      padding: 31px 20px;
      grid-template-columns: auto auto auto auto;
      grid-gap: 8px;
      width: calc(400% + 40px);
    }
  }

  &__item {
    position: relative;
    border-radius: 16px 0;
    overflow: hidden;
    box-shadow: -4px -4px 8px #FFFFFF, 4px 4px 8px #D6D6D6;
    border: 1px solid #EDEDED;
  }

  &__image {
    display: block;
    padding-top: 60%;

    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    img {
      display: none;
    }
  }

  &__label {
    position: absolute;
    left: 16px;
    bottom: 16px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    padding: 8px 24px;
    background: linear-gradient(98.27deg, #EDEDED, #EDEDED);
    border: 1px solid #B6B6B6;
    border-radius: 16px 0;
  }

  &__link {
    display: block;
  }
}
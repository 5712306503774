.review-form {
  &__title {
    font-size: 26px;
    line-height: var(--title-line-height);
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;

    @include respond(XS) {
      font-size: 20px;
      margin-bottom: 23px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__field-name {
    margin-bottom: 24px;

    @include respond(XS) {
      margin-bottom: 27px;
    }
  }

  &__field-comment {
    margin-bottom: 32px;
  }

  &__field-image {
    margin-bottom: 32px;
  }

  &__button {
    margin-bottom: 24px;

    @include respond-down(S) {
      margin-bottom: 16px;
    }
  }

  &__policy {
    text-align: center;
  }
}
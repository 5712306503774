.range-input {
  //padding: 0 10px;
  &__dash{

  }

  &__values {
    display: flex;
    justify-content: space-between;
  }

  &__value {
    display: flex;
    align-items: center;
    position: relative;

    label {
      margin-bottom: 0;
      margin-right: 5px;
      font-size: 12px;
    }

    input[type="text"] {
      text-align: center;
      height: 27px;
      font-size: 14px;
      width: 110px;
      color: black;
    }

    #CatalogFilterForm_range_price_from {
      padding: 0 10px;
    }

    #CatalogFilterForm_range_price_to {
      padding: 0 10px;
    }

    input[type="text"]:focus {
      font-weight: 700;
      color: black;
    }

    &_from {

    }

    &_to {

    }
  }

  #CatalogFilterForm_range_height_line, #CatalogFilterForm_range_width_line {
    &.noUi-horizontal {
      .noUi-origin {
        .noUi-handle {
          .noUi-tooltip {
            display: none;
          }

          .noUi-touch-area {
            position: absolute;
            top: 0;
            background: black;
            width: 10px;
            height: 10px;
            border-radius: 10000px;
            transform: rotate(45deg);
            left: -14px;
          }
        }
      }
    }
  }

  &__postfix {
    position: absolute;
    right: 20px;
    font-size: 12px;
    top: 6.5px;
    color: black;
  }

  &__input {
    display: none !important;
  }

  &__label, &__show {

  }

  &__show {
    color: black;
    font-weight: 400;
    font-family: var(--font);
    font-style: italic;
    font-size: 16px;
    letter-spacing: 0.8px;
  }

  &__line {
    margin: 24px 25px 28px;

    &.noUi-horizontal {
      height: 2px;
      border: none;
      background-color: #B6B6B6;
      box-shadow: none;
      //padding: 0 6px;
      //margin: 24px 0 28px;

      .noUi-origin {
        .noUi-handle {
          height: 1px;
          width: 1px;
          background: transparent;
          right: -5px;
          border-radius: unset;
          top: -5px;

          &.noUi-handle-upper {
            right: -6px;

          }

          &::after, &::before {
            display: none;
          }

          .noUi-tooltip {
            display: none;
            border-radius: 5px;
            background: black;
            color: white;
            font-family: var(--font);
            font-weight: bold;
            font-size: 10px;
            letter-spacing: -0.02em;
            text-align: center;
            bottom: -35px;
            min-width: 40px;
            border: none;
            left: -5px;

          }

          .noUi-touch-area {
            position: absolute;
            top: -3px;
            background: linear-gradient(92.29deg, #FDBD5E 0%, #F2CA6E 100%);
            width: 16px;
            height: 16px;
            left: -12px;
            border-radius: 4px;
            transition: var(--default-transition);

            &:hover{
              background: linear-gradient(92.29deg, #FDBD5E 100%, #F2CA6E 100%);
            }
          }
        }
      }
    }

    .noUi-connect {
      background-color: var(--col_yellow);
      height: 2px;
    }

  }
}
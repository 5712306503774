.new-product {
  margin-bottom: 160px;

  @include respond-down(L){
    margin-bottom: 145px;
  }

  @include respond-down(M){
    margin-bottom: 126px;
  }

  @include respond-down(S){
    margin-bottom: 90px;
  }

  &__title-row {
    padding-left: 20%;
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
    overflow: hidden;

    @include respond-down(L){
      margin-bottom: 35px;
    }

    @include respond-down(M){
      margin-bottom: 30px;
    }

    @include respond-down(S){
      margin-bottom: 24px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 24px;
    font-size: 38px;
    font-weight: bold;
    position: relative;
    &:after{
      position: absolute;
      top: 39px;
      display: block;
      background: var(--col_yellow-line);
      content: '';
      height: 16px;
      right: 0;
      left: -14px;
      z-index: 1;

      @include respond-down(L){
        top: 35px;
      }
      @include respond-down(M){
        height: 8px;
        top: 23px;
      }
      @include respond-down(S){
        height: 8px;
        top: 20px;
      }
    }

    @include respond-down(M){
      font-size: 22px;
      margin-bottom: 16px;
    }

    @include respond-down(S){
      font-size: 20px;
    }
  }

  &__title-text{
    position: relative;
    z-index: 3;
  }


  &__description {
    display: block;
    font-size: 16px;

    @include respond-down(M){
      font-size: 14px;
    }

    @include respond-down(S){
      font-size: 12px;
    }
  }


}
.faq-preview {
  display: block;

  &__inner {
    display: block;
    background: var(--col_main-grey);
    border: 1px solid var(--col_main-border);
    border-radius: 16px;
    padding: 32px 42px;
    margin-bottom: 24px;

    @include respond-down(XS) {
      padding: 16px;
      margin-bottom: 0;
    }
  }

  &__question {
    display: block;
    font-weight: bold;
    margin-bottom: 16px;

    @include respond-down(XS) {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }

  &__answer {
    display: block;

    @include respond-down(XS) {
      font-size: 14px;
    }
  }
}
/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/



:root {
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.6;
  --title-line-height: 1.5;

  --font:  'Montserrat', sans-serif;
  --h-font:  'Montserrat', sans-serif;

  --t: transparent;

  --primary-color: var(--col_yellow);
  --alert-color: var(--col_form-error);
  --default-transition: all 0.4s;
  --primary-color-opacity: var(--col_yellow-hover);

  --col_yellow: #FDBD5E;
  --col_yellow-hover: #F2CA6E;
  --col_yellow-line: #F8C365;;
  --col_grey: #C4C4C4;
  --col_main-border:#D6D6D6;
  --col_main-grey: #F0F0F0;
  --col_main-white: #FFFFFF;
  --col_main-yellow: #F2CA6E;
  --col_contacts-name: #767676;
  --col_form-error: #FD8E5E;

}

/* Корпусная мебель под заказ по индивидуальным размерам недорого в Кирове. Официальный договор на изготовление индивидуальной мебели под заказ Киров. */





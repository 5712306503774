.material-info {
  &__item {
    display: flex;
    margin-bottom: 25px;

    @include respond(XS) {
      flex-direction: column-reverse;
      font-size: 14px;
      margin-bottom: 18px;
    }
  }

  &__image {
    width: 40%;
    object-fit: contain;
    object-position: center top;
    margin-right: 24px;

    @include respond-down(M) {
      margin-right: 18px;
    }

    @include respond(XS) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__paragraph {
    margin-bottom: 1em;
  }
}
.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @include respond-down(M) {
    margin-bottom: 20px;
  }

  @include respond(XS) {
    flex-direction: column;
    align-items: stretch;
  }

  &__h1 {
    font-size: 38px;
    font-weight: bold;

    @include respond-down(L){
      font-size: 28px;
    }

    @include respond-down(M){
      font-size: 22px;
    }

    @include respond(XS) {
      margin-bottom: 15px;
    }
  }
}
.form {
  button {
    border: none;
  }

  &__wrapper-field {
    width: 100%;
    min-width: 0;
    position: relative;
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      line-height: 1;
      margin-bottom: 8px;

      &.required {
        &:after {
          display: block;
          background: var(--col_yellow);
          width: 8px;
          height: 8px;
          content: '';
          position: absolute;
          top: 4px;
          right: 0;
          border-radius: 3px;

          @include respond-down(XS) {
            width: 5px;
            height: 5px;
            top: 8px;
          }
        }
      }
    }

    input {
      position: relative;
      z-index: 2;
      border: none;
      padding: 20px 22px;
      font-size: 18px;
      box-shadow: inset -4px -4px 8px #FFFFFF, inset 4px 4px 8px #D6D6D6;
      border-radius: 16px;
      background: linear-gradient(102.02deg, #FFFFFF 0%, #EDEDED 52.08%);

      @include respond-down(XS) {
        padding: 15px 24px;
        font-size: 14px;
      }
    }

    textarea {
      border: none;
      padding: 20px 22px;
      font-size: 18px;
      font-family: var(--font);
      box-shadow: inset -4px -4px 8px #FFFFFF, inset 4px 4px 8px #D6D6D6;
      border-radius: 16px;
      background: linear-gradient(95.13deg, #FFFFFF 0%, #EDEDED 52.08%);
      resize: vertical;
      min-height: 62px;
      height: 147px;
    }

    .errors {
      z-index: 1;
      display: block;
      background: var(--col_form-error);
      height: 20px;
      border-radius: 0 0 16px 16px;
      position: absolute;
      right: 0;
      left: 0;
      bottom: -4px;

      li {
        display: none;
      }
    }

    &_price {
      label {
        display: none;
      }

      input {
        padding: 7px 22px;
        font-size: 14px;
      }
    }

    &_menu {
      input {
        padding: 15px 22px;
        font-size: 14px;

        @include respond-down(XS) {
          padding: 11px 24px;
        }
      }
    }
  }

  &__checkbox {
    label {
      background: linear-gradient(101.6deg, #FFFFFF 0%, #F0F0F0 100%);
      border: 1px solid #EDEDED;
      box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #E5E5E5;
      padding: 7px 24px 7px 68px;
      display: block;
      border-radius: 16px;
      transition: var(--default-transition);
      text-align: left;

      @include respond-down(M) {
        padding: 8px 24px 9px 68px;
      }

      position: relative;
      z-index: 2;

      .form__checkbox-image {
        top: 50%;
        transform: translateY(-50%);
        left: 38px;
        position: absolute;
        z-index: 3;
        display: block;
        background: {
          position: center;
          size: cover;
        };

        width: 16px;
        height: 16px;
      }
    }

    input {
      display: none;

      &:checked + label {
        background: linear-gradient(109.12deg, #F0F0F0 0%, #E5E5E5 100%);
        box-shadow: inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #D6D6D6;
        border-radius: 16px;
      }
    }
  }

  &__upload {
    label {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.35;
      font-size: 18px;
      border: 1px solid #EDEDED;
      box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #E5E5E5;
      padding: 17.5px 24px;
      border-radius: 16px;
      background: linear-gradient(101.6deg, #FFFFFF, #F0F0F0);

      &:before {
        margin-right: 8px;
        content: '';
        display: block;
        height: 25px;
        width: 25px;
        background: {
          image: url('/static/images/svg/upload.svg');
          repeat: no-repeat;
          position: center;
        }
      }

      @include respond-down(XS) {
        font-size: 14px;
        padding: 10.5px 24px;
      }

      @include respond-up(L) {
        transition: var(--default-transition);

        &:hover {
          box-shadow: -12px -12px 24px #FFFFFF, 12px 12px 24px #E5E5E5;
          transition: var(--default-transition);
        }

        &:active {
          box-shadow: inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #D6D6D6;
        }
      }
    }

    input {
      display: none;
    }

    &_modal {
      @include respond-down(XS) {
        label {
          &:before {
            margin-right: 16px;
            height: 35px;
            width: 35px;
          }
        }
      }
    }
  }

  &__block-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--col_main-grey);
    opacity: 0;
    pointer-events: none;
    transition: var(--default-transition);
  }

  &__success-image {
    margin-bottom: 56px;

    @include respond(XS) {
      margin-bottom: 35px;
    }
  }

  &__success-title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;

    @include respond(XS) {
      font-size: 20px;
      width: 212px;
      margin-bottom: 28px;
    }
  }

  &__success-text {
    font-size: 26px;
    text-align: center;
    width: 315px;
    margin-bottom: 80px;

    @include respond(XS) {
      font-size: 14px;
      width: 212px;
      margin-bottom: 42px;
    }
  }

  &_low-success {
    .form__success-image {
      margin-bottom: 34px;
    }

    .form__success-title {
      margin-bottom: 19px;
    }

    .form__success-text {
      margin-bottom: 48px;
    }
  }

  &_menu {
    .form__block-success {
      padding-top: 300px;
      top: -700px;
      right: -68px;
      left: -68px;
      bottom: -46px;

      @include respond(XS) {
        padding-top: 450px;
        right: -28px;
        left: -28px;
        bottom: -30px;
      }
    }
  }

  &.success {
    .form__block-success {
      opacity: 1;
      pointer-events: auto;
      transition: var(--default-transition);
    }
  }

  &__policy {
    font-size: 12px;
    line-height: 1.4;

    &_smaller {
      @include respond(XS) {
        font-size: 8px;
      }
    }
  }

  &__policy-link {
    color: var(--col_yellow);
  }
}
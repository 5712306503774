.leader {
  @include respond-up(XL) {
    margin-bottom: 160px;
  }

  @include respond(L) {
    margin-bottom: 145px;
  }

  @include respond(M) {
    margin-bottom: 126px;
  }

  @include respond-down(S) {
    margin-bottom: 90px;
  }

  &__title-row {
    position: relative;
    z-index: 2;
    margin-bottom: 40px;

    @include respond-down(L) {
      margin-bottom: 35px;
    }

    @include respond-down(M) {
      margin-bottom: 30px;
    }

    @include respond-down(S) {
      margin-bottom: 24px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 24px;
    font-size: 38px;
    font-weight: bold;

    &:after {
      position: absolute;
      left: 0;
      top: 39px;
      display: block;
      background: var(--col_yellow-line);
      content: '';
      height: 16px;
      right: calc((((100vw - var(--max-row-width) * 1px) / 2) + var(--max-row-width) * 1px) - 500px);
      z-index: 1;

      @include respond-down(L) {
        right: 67%;
        top: 35px;
      }

      @include respond-down(M) {
        height: 8px;
        top: 23px;
        right: 70%;
      }
      @include respond-down(S) {
        height: 8px;
        top: 20px;
        right: 72%;
      }
      @include respond-down(S) {
        right: 48%;
      }
    }

    @include respond-down(M) {
      font-size: 22px;
      margin-bottom: 16px;
    }

    @include respond-down(S) {
      font-size: 20px;
    }
  }

  &__title-text {
    position: relative;
    z-index: 3;
  }

  &__description {
    display: block;
    font-size: 16px;


    @include respond-down(M) {
      font-size: 14px;
    }

    @include respond-down(S) {
      font-size: 12px;
    }
  }

  &__list {
    display: grid;

    @include respond-up(L) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 22px;
    }
    @include respond(M) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 50px;
    }
    @include respond-down(S) {
      grid-row-gap: 52px;
    }
  }
}

@include respond-up(XL) {
  .leader {
    &__link {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;

      a {
        width: 256px;
      }
    }
  }
}

@include respond(L) {
  .leader {
    &__link {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;

      a {
        width: 256px;
      }
    }
  }
}

@include respond-down(M) {
  .leader {
    &__link {
      margin-top: 60px;
      display: flex;

      a {
        width: 100%;
      }
    }
  }
}

@include respond-down(S) {
  .leader {
  }
}
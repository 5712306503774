.material-preview {
  position: relative;

  @include respond-down(M) {
    font-size: 14px;
  }

  &__wrapper-link {
    background-color: var(--col_main-grey);
    display: flex;
    border-radius: 16px;
    border: 1px solid var(--col_main-border);
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  &__image {
    padding-top: 47%;
    display: block;

    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    img {
      display: none;
    }
  }

  &__title {
    padding: 19px 27.5px 7px;
    justify-content: space-between;
    display: flex;
    align-items: baseline;
    font-weight: bold;

    @include respond-down(M) {
      padding: 19px 18px 7px;
    }

    @include respond(XS) {
      padding: 20px 16px 12px;
    }
  }

  &__name {
    font-size: 20px;
    line-height: var(--title-line-height);

    @include respond-down(M) {
      font-size: 18px;
    }

    @include respond(XS) {
      font-size: 14px;
    }
  }

  &__time {
    color: var(--col_grey);
  }

  &__description {
    display: block;
    padding: 0 27.5px 50px;

    @include respond-down(M) {
      padding: 0 18px 24px;
    }

    @include respond(XS) {
      padding: 0 16px 16px;
    }
  }

  &__button {
    align-self: center;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);

    @include respond-up(L) {
      opacity: 0;
      pointer-events: none;
      transition: var(--default-transition);
    }

    @include respond-down(M) {
      display: none;
    }
  }

  @include respond-up(L) {
    &:hover {
      .material-preview__button {
        pointer-events: auto;
        opacity: 1;
        transition: var(--default-transition);
      }
    }
  }
}
.stock-info {
  position: relative;
  margin-bottom: 123px;

  @include respond-down(M) {
    margin-bottom: 65px;
  }

  @include respond(XS) {
    margin-bottom: 60px;
  }


  &__top {
    padding-left: calc(40% + 15px);

    @include respond-down(M) {
      padding-left: 0;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 20px;

    @include respond-down(M) {
      font-size: 22px;
    }

    @include respond(XS) {
      margin-bottom: 24px;
    }
  }

  &__container {
    position: relative;
    display: flex;

    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: var(--col_yellow);
      bottom: -35px;
      left: 10%;
      padding-right: 446px;
      width: 30%;
      height: 96px;
      border-radius: 0 16px;


      @include respond-down(M) {
        bottom: -28px;
        padding-right: 0;
        height: 140px;
        left: 17%;
        width: 83%;
      }

      @include respond(XS) {
        height: 80px;
        bottom: -20px;
        left: 0;
        width: 100%;
      }
    }
  }

  &__image {
    z-index: 1;
    display: block;
    width: 40%;
    padding-top: 19.5%;
    margin-right: 25px;
    border-radius: 16px 0;

    background: {
      repeat: no-repeat;
      size: cover;
      position: left center;
    }

    img {
      display: none;
    }

    @include respond-down(M) {
      position: absolute;
      width: 100%;
      padding-top: 47%;
      bottom: 48px;
      margin-right: 0;
    }

    @include respond(XS) {
      bottom: 60px;
    }
  }

  &__wrapper-description {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    @include respond-down(M) {
      width: 100%;
    }
  }

  &__description {
    display: block;
    margin-bottom: 33px;

    @include respond-down(M) {
      margin-bottom: calc(47% + 21px);
    }

    @include respond(XS) {
      margin-bottom: calc(47% + 15px);
      font-size: 14px;
    }
  }

  &__date {
    display: flex;
    z-index: 1;

    @include respond-down(M) {
    margin-left: 36%;
    }

    @include respond(XS) {
      flex-direction: column;
      font-size: 14px;
      margin-left: 8%;
    }
  }

  &__date-text {
    margin-right: 8px;

    @include respond(XS) {
      margin-right: 0;
    }
  }

  &__date-value {
    font-weight: bold;
  }
}
.types {
  margin-bottom: 130px;

  @include respond-down(M) {
    margin-bottom: 70px;
  }

  @include respond(XS) {
    margin-bottom: 60px;
  }

  &__title {
    font-weight: bold;
    font-size: 38px;
    line-height: var(--title-line-height);
    margin-bottom: 24px;

    @include respond-down(M) {
      margin-bottom: 16px;
      font-size: 22px;
    }

    @include respond(XS) {
      margin-bottom: 8px;
      font-size: 20px;
    }
  }

  &__description {
    margin-bottom: 32px;

    @include respond-down(M) {
      font-size: 14px;
    }

    @include respond(XS) {
      margin-bottom: 27px;
      font-size: 12px;
    }
  }
}
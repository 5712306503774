.sidebar {

  &._open{

  }

  &__wrapper-form {
    border: 1px solid var(--col_main-border);
    margin-bottom: 96px;
  }

  &__form-inner{
    &__right{

    }
  }

  &__form-closer{
    display: none;

    @include respond-down(M){
      display: flex;
    }
  }

  &__form {
    background: var(--col_main-grey);
    padding: 52px 32px 64px;
    position: relative;
    @include respond-down(L){
      padding: 40px 23px 40px;
    }

    @include respond-down(M) {
      display: grid;
      grid-template-columns: 260px 260px;
      justify-content: space-between;
      padding: 52px 32px 40px;
    }
    @include respond-down(S){
      grid-template-columns: 420px;
      padding: 52px 32px 64px;
      justify-content: center;
    }

    @include respond-down(XS){
      grid-template-columns: 1fr;
    }

  }

  &__form-field{
    margin-bottom: 48px;



    .label{
      margin-bottom: 22px;
    }
    &_sort{
      margin-bottom: 41px;

      @include respond-down(S){
        margin-bottom: 32px;
      }

      label{
        padding: 10px 0 ;
        &:after{
          opacity: 0;
          z-index: 2;
          content: 'дешевые';
          position: absolute;
          bottom: 0;
          left: 79px;
          border-bottom: 1px dashed var(--col_main-border);
          top: 10px;
          font-size: 14px;
          background: var(--col_main-grey);
          padding-bottom: 2px;
          display: block;
          height: 17px;
        }

        &:before{
          opacity: 1;
          z-index: 2;
          content: 'дорогие';
          position: absolute;
          bottom: 0;
          left: 79px;
          border-bottom: 1px dashed var(--col_main-border);
          top: 10px;
          font-size: 14px;
          background: var(--col_main-grey);
          padding-bottom: 2px;
          display: block;
          height: 17px;
        }
      }

      input{
        display: none;

        &:checked+label{
          &:after{
            opacity: 1;
          }
          &:before{
            opacity: 0;
          }
        }
      }

    }

    &_range{
        @include respond(S){
          padding: 0 15%;
        }
        @include respond(XS){
          padding: 0;
        }


    }

    &_type{
      @include respond(S){
        padding: 0 15%;
      }
      @include respond(XS){
        padding: 0;
      }

      ul{
        li{
          margin-bottom: 18px;
        }
      }
    }

    &_action{
      margin-bottom: 0;

      label{
        padding: 7px 0;
        text-align: center;
      }
    }

  }

  &__closer-filter{
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;

    @include respond-down(M){
      display: flex;
    }
  }

  &__wrapper-seotext {
    @include respond-down(M){
      display: none;
    }
  }

  &__seotext-title {
    display: block;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;

    color: #000000;


  }

  &__seotext-text {

  }
}
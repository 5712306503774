.menu {
  position: fixed;
  z-index: 4;
  left: 0;
  right: 0;
  opacity: 0;

  transition-delay: 0.1s;
  transition: all 0.2s;
  display: flex;
  visibility: hidden;
  pointer-events: none;
  top: 130px;
  bottom: 0;

  @include respond-down(S) {
    top: 100px;
  }

  @include respond-down(XS) {
    top: 72px;
  }

  &._big-top{
    top: 183px;

    .menu__close{
      top: 160px;
    }

    @include respond-down(S) {
      top: 126px;

      .menu__close{
        top: 108px;
      }
    }
  }

  &._open {
    pointer-events: auto;
    transition-delay: 0s;
    transition: opacity 0.2s;
  }

  &._visible {
    opacity: 1;
    visibility: visible;
  }

  &__close {
    z-index: 10;
    position: fixed;
    right: 40px;
    top: 110px;

    @include respond-down(S) {
      right: 30px;
      top: 75px;
    }

    @include respond(XS) {
      right: 22px;
      top: 55px;
      padding: 8px;
      width: 32px;
      height: 32px;

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  &__border {
    width: 100%;
    border: 1px solid var(--col_main-border);
    overflow-y: auto;
    max-height: 900px;
  }

  &__container {
    flex: 1 1 auto;
    display: flex;
    padding-bottom: 25px;
  }

  &__inner {
    height: 100%;
    position: relative;
    background-color: #F0F0F0;
    transition: var(--default-transition);
    flex-direction: column;
    justify-content: space-between;
    padding: 96px 80px;
    flex: 1 1 auto;
    display: flex;
    min-height: 600px;

    @include respond-down(XS){
      min-height: 550px;
    }

    @include respond-down(XS){
      padding: 48px 32px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__list-link {
    padding: 20px 0;
    display: block;

    @include respond-down(M) {
      padding: 10px;
    }

    @include respond(XS) {
      padding: 8px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    right: 68px;
    left: 68px;
    bottom: 46px;

    @include respond(XS) {
      flex-direction: column;
      align-items: stretch;
      right: 28px;
      left: 28px;
      bottom: 30px;
    }
  }

  &__form-line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 18px;

    @include respond(XS) {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 16px;
    }
  }

  &__form-field {
    margin-right: 22px;

    @include respond(XS) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__form-button {
    min-width: 210px;
    margin-top: 20px;

    @include respond(S) {
      min-width: 170px;
    }

    @include respond(XS) {
      min-width: auto;
      margin-top: 0;
    }
  }
}